import { createAsyncThunk } from "@reduxjs/toolkit";
// import { BusinessChatEnum, ChatListSelection, ThunkApiEnum, VagaroAPIEnum } from "../../Container/Enums";
// import { AjaxGenerateToken, AsyncAjaxGet } from "./AsyncAjax";
import axios from "axios";
import { AjaxGenerateToken, AsyncAjaxGet, AsyncAjaxPost, AsyncAjaxStaticGet } from "./AsyncAjaxServiceV2";
import { ThunkApiEnum, VagaroAPIEnum } from "../../Vagaroconnect/Binding/ChatBoatEnums";
import { utils } from "../../utils/Utils";
import useGenerateToken from "../Hooks/useGenerateToken";
// import { SetChatProvider } from "../../Store/Slice/ChatProviderSlice";


let Token = [];
let TextMarketing = [];
let AISettings = [];
let GetOldAISettings = [];
const { fillStringTemplate } = utils;

export const ThunkAPI = async (dispatch, type) => {
    if (true) {
        switch (type) {
            case ThunkApiEnum.TextMarketing:
                dispatch(fetchTextMarketing())
                break;
            case ThunkApiEnum.AiSetting:
                dispatch(fetchAISettings())
                break;
            case ThunkApiEnum.UpdateSetting:
                dispatch(UpdateAISettings())
                break;
            case ThunkApiEnum.UpdateAIPauseSettings:
                dispatch(UpdateAIPauseSettings())
                break;
            default:
                break;
        }
    }
};
export const HandleToken = async (token) => {
    if (token) {
        await token.cancel('Operation canceled due to new request.');
    }
    return axios.CancelToken.source();
};
export const TokenGenerate = createAsyncThunk(
    'PostGenerateToken',
    async (Request, thunkApi) => {
        const { generate_time } = thunkApi.getState().Token;
        if (typeof generate_time === "undefined" ||
            generate_time === null ||
            generate_time === "" || Date.parse(new Date()) > generate_time) {
            await AjaxGenerateToken(
                VagaroAPIEnum.GenerateToken,
                Request,
                function onSuccess(data) {
                    if (data != null && data.data != null) {
                        Token = data.data;
                    }
                    else {
                        return thunkApi.rejectWithValue([]);
                    }
                },
                function OnError() {
                    return thunkApi.rejectWithValue([]);
                }
            );
            return thunkApi.fulfillWithValue(Token);
        }
        else {
            return true;
        }
    }
);

export const fetchTextMarketing = createAsyncThunk(
    'TextMarketing',
    async (request, thunkApi) => {
        const { access_token } = thunkApi.getState().Token;
        await AsyncAjaxGet(
            fillStringTemplate(VagaroAPIEnum.Textmarketing, ThunkApiEnum.Merchant, ThunkApiEnum.User),
            access_token,
            function onSuccess(data) {
                if (data != null && data.data != null) {
                    TextMarketing = data.data;
                }
                else {
                    return thunkApi.rejectWithValue([]);
                }
            },
            function OnError() {
                return thunkApi.rejectWithValue([]);
            },
            false
        );
        return thunkApi.fulfillWithValue(TextMarketing);
    }
);
export const fetchAISettings = createAsyncThunk(
    'Settings',
    async (request, thunkApi) => {
        const { access_token } = thunkApi.getState().Token;
        await AsyncAjaxStaticGet(
            fillStringTemplate(VagaroAPIEnum.GetAiSettings, ThunkApiEnum.Merchant, ThunkApiEnum.User),
            access_token,
            function onSuccess(data) {
                if (data && data.data && data.data?.data) {
                    const { Status, TextMarketingEnable } = thunkApi.getState().TextMarketing;
                    if (Status === "Done" && TextMarketingEnable === false) {
                        thunkApi.dispatch(fetchTextMarketing());
                    }
                    AISettings = data.data.data;
                    GetOldAISettings = data.data.data;
                }
                else {
                    return thunkApi.rejectWithValue([]);
                }
            },
            function OnError() {
                return thunkApi.rejectWithValue([]);
            },
            true
        );
        return thunkApi.fulfillWithValue(AISettings);
    }
);
export const UpdateAISettings = createAsyncThunk(
    'Settings',
    async (request, thunkApi) => {
        const { access_token } = thunkApi.getState().Token;
        const { Pause, CustomHours, AISettings, Activate } = thunkApi.getState().Settings;
        const activeCustomHours = CustomHours.filter(hour => hour.activate === true)
            .map(({ dayID, openTime, closeTime }) => ({
                dayID,
                openTime,
                closeTime
            }));
        var requestData;
        if (Activate === false) {
            requestData = {
                active: Activate,
                userid: ThunkApiEnum.User,
                customhours: GetOldAISettings.customHours || [],
                aiReplySettings: GetOldAISettings.aiReplySettings || 1,
                ispause: GetOldAISettings.isPause || false,
            }
        } else {
            requestData = {
                customhours: activeCustomHours,
                aiReplySettings: AISettings,
                ispause: Pause,
                active: Activate,
                userid: ThunkApiEnum.User,
            };
        }
        await AsyncAjaxPost(
            fillStringTemplate(VagaroAPIEnum.AiSetting, ThunkApiEnum.Merchant, ThunkApiEnum.User),
            requestData,
            access_token,
            function onSuccess(data) {
                if (data != null && data.data != null) {
                    document.getElementById('dvLastModified2010').innerHTML = !utils.isNullEmptyOrWhiteSpace(data.data.data) ? data.data.data : '';
                    return thunkApi.fulfillWithValue([]);
                }
                else {
                    return thunkApi.rejectWithValue([]);
                }
            },
            function OnError() {
                return thunkApi.rejectWithValue([]);
            },
            true
        );

    }
);

export const UpdateAIPauseSettings = createAsyncThunk(
    'Settings',
    async (request, thunkApi) => {
        const { access_token } = thunkApi.getState().Token;
        const { Pause, Activate } = thunkApi.getState().Settings;
        var requestData;
        requestData = {
            ispause: Pause,
            active: Activate
        };
        await AsyncAjaxPost(
            fillStringTemplate(VagaroAPIEnum.AiSetting, ThunkApiEnum.Merchant, ThunkApiEnum.User),
            requestData,
            access_token,
            function onSuccess(data) {
                if (data != null && data.data != null) {
                    document.getElementById('dvLastModified2010').innerHTML = !utils.isNullEmptyOrWhiteSpace(data.data.data) ? data.data.data : '';
                    return thunkApi.fulfillWithValue([]);
                }
                else {
                    return thunkApi.rejectWithValue([]);
                }
            },
            function OnError() {
                return thunkApi.rejectWithValue([]);
            },
            true
        );

    }
);

