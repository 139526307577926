import React from 'react'
import { ProgressBar } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { MobileCallBackAction, StatusEnum } from '../Binding/ChatBoatEnums';
import { utils } from '../../utils/Utils';
import { useVagaroAIContext } from '../Store/VagaroAIContext';

const TextMarketing = () => {
    const { TextMarketingEnable, Usage, PlanUsageLimit, ActiveSMSForMessenger, FromTextMessengerPhone, NumberStatus, PlanName, OverLimitRateInCentsText, Percentage, Variant, Status } = useSelector((state) => state.TextMarketing);
    const { isAndroidIOSMobile, ReactBusinessId, initialData } = useVagaroAIContext();
    const { CallBackGivenToMobile } = utils;
    const OpenTextMessagingPlanPopup = (isEnable2WaySMS) => {
        if (initialData.TextMessagingModifyRights.toLowerCase() === "false") {
            utils.ShowAlert("Alert", "You Don't Have Rights!!", "Close");
            return <></>;
        }
        let isIpad = /Macintosh/i.test(navigator.userAgent) && navigator.maxTouchPoints && navigator.maxTouchPoints > 1;
        let isIpadold = /iPad/i.test(navigator.userAgent);
        let isandroid = /VagaroAndroidPhone/i.test(navigator.userAgent);
        let isandroidTab = /VagaroAndroidTablet/i.test(navigator.userAgent);

        if (isAndroidIOSMobile || isIpad || isIpadold || isandroid || isandroidTab) {
            CallBackGivenToMobile(MobileCallBackAction.OpenTextMessaging, "")
        } else {
            var jsonData = JSON.stringify({ isEnable2WaySMS: isEnable2WaySMS }).replace(/\\\"/g, '"').replace(/"/g, "\\\"");
            window.parent.postMessage('{"eventType": "OpenTextMessagingPlanPopup","Data": "' + jsonData + '"}', '*');
        }
    }
    const getStatusText = (numberStatus) => {
        switch (numberStatus) {
            case 1:
            case 2:
                return "Pending Review";
            case 3:
                return "In Review";
            case 4:
                return "Approved";
            case 5:
                return "Rejected";
            default:
                return "Pending Review";
        }
    }

    if (ReactBusinessId !== 226 && TextMarketingEnable) {
        return (
            <div className={`textCounArea ${Status === StatusEnum.Loading ? "stenciled" : ""}`}>
                <div className="textCounArea-sent">
                    <span className="textCounArea-sent_count">{Usage}</span>Texts Sent
                </div>
                <ProgressBar now={Percentage} variant={Variant} />
                <div className="textCounArea-limit"
                    onClick={() => OpenTextMessagingPlanPopup(false)}
                >
                    <span>{PlanName} - Limit: {PlanUsageLimit}</span>
                </div>
                {ActiveSMSForMessenger !== null && ActiveSMSForMessenger === 0 &&
                    <div className="textCounArea-limit"
                        onClick={() => OpenTextMessagingPlanPopup(true)}
                    >
                        <span>Enable 2-Way SMS</span>
                    </div>
                }
                {Usage >= PlanUsageLimit &&
                    <div className="mail-sent-per">{OverLimitRateInCentsText}</div>
                }
                <div className="textCounArea-contact">
                    {FromTextMessengerPhone}
                </div>
                {NumberStatus !== null && NumberStatus > 0 &&
                    <div className="textCounArea-status-msg">(Status : {getStatusText(NumberStatus)})</div>
                }
            </div>
        )
    } else {
        return <></>;
    }
}

export default TextMarketing