import React from "react";
import LeftComponent from "./SettingComponents/LeftComponent";
import RightComponent from "./SettingComponents/RightComponent";
import { ConnectMidWrap, ConnectSettingWrap, Loader, SettingFooter, SettingHeader, TextmaktingUpdate } from "./SettingComponents/Components";
import { VagaroAIProvider } from "../Store/VagaroAIContext";

const VagaroAI = (props) => {
  
  return (
    <div>
      <VagaroAIProvider initialData={props}>
        <Loader />
        <ConnectSettingWrap>
          <SettingHeader />
          <ConnectMidWrap>
            <LeftComponent />
            <RightComponent />
          </ConnectMidWrap>
          <SettingFooter />
        </ConnectSettingWrap>
        <TextmaktingUpdate/>
      </VagaroAIProvider>
    </div>
  );
};

export default VagaroAI;