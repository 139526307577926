import $ from "jquery";
const isConnectApp =
    typeof window.isFromConnectApp === "undefined" ||
        window.isFromConnectApp == null ||
        window.isFromConnectApp === 0 ||
        window.isFromConnectApp === ""
        ? false
        : window.isFromConnectApp;


export const LeftControlNames = {
    Customer: "Customer",
    NewCustomer: "NewCustomer",
    Employee: "Employee",
    NewEmployee: "NewEmployee",
}

export const StatusEnum = {
    Idle: "Idle",
    Loading: "Loading",
    Sucess: "Sucess",
    Done: "Done",
};
export const AISettingEnum = {
    replyToAll: 1,
    replyDuringOffHours: 2,
    replyDuringCustomHours: 3,
};

export const ThunkApiEnum = {
    Merchant: '',
    User:'',
    TextMarketing: "TextMarketing",
    AiSetting:'AiSetting',
    UpdateSetting:'UpdateSetting',
    UpdateAIPauseSettings : 'UpdateAIPauseSettings'
};
export const CustomHoursEnum = {
    dayID: 0,
    name: "",
    activate: false,
    openTime: "Start",
    closeTime: "End"
};
 
export const VagaroAPIEnum = {
    Textmarketing: "{1}/VagaroConnect/gettextusage?UserId={2}&Category=PromotionText",
    AiSetting: `connect/{1}/aisettings`,
    GetAiSettings:`connect/vagaroconnectchat/aisettings`,
};
export const CommonMethods = {
    IsElement: (id = "") => { const element = document.getElementById(id); return !!element },
    GetElement: (id = "") => { const element = $(id); return element },
    Onclick: (id = "") => { try { $("#" + id).click() } catch (error) { console.log(error) } },
    Actionclick: (id = "") => { try { document.getElementById(id).click(); } catch (error) { console.log(error) } },
    TriggerClick: (query = "") => { try { document.querySelectorAll(query).forEach(ele => ele.click()) } catch (error) { console.log(error) } },
    ShowElement: (id = "") => { try { $(id).show(); } catch (error) { console.log(error) } },
    HideElement: (id = "") => { try { $(id).hide(); } catch (error) { console.log(error) } },
    AddClass: (id = "", Class = "") => { try { $(id).addClass(Class) } catch (error) { console.log(error) } },
    RemoveClass: (id = "", Class = "") => { try { $(id).removeClass(Class) } catch (error) { console.log(error) } },
    AddClassToBody: (Class = "") => { try { $("body").addClass(Class) } catch (error) { console.log(error) } },
    DelayCall: (CallBack = () => { }, timeout = 100) => { try { setTimeout(() => { CallBack(); }, timeout); } catch (error) { console.log(error) } },
    RemoveClassToBody: (Class = "") => { try { $("body").removeClass(Class) } catch (error) { console.log(error) } },
    RemoveClassbyClass: (Class = "") => { try { document.querySelectorAll('.' + Class).forEach(ele => ele.classList.remove(Class)) } catch (error) { console.log(error) } },
    RemoveClassbyClassName: (Class = "",ClassName ="") => { try { document.querySelectorAll('.' + Class).forEach(ele => ele.classList.remove(ClassName)) } catch (error) { console.log(error) } },
    RemoveElementbyID: (id = "") => { try { document.getElementById(id).remove() } catch (error) { console.log(error) } },
    RemoveElementbyClass: (Class = "") => { try { document.querySelectorAll('.' + Class).forEach(ele => ele.remove()) } catch (error) { console.log(error) } },
    RemoveClassbyClassName: (Class = "",ClassName ="") => { try { document.querySelectorAll('.' + Class).forEach(ele => ele.classList.remove(ClassName)) } catch (error) { console.log(error) } },
    RemoveSelectionForMobile: (isIos = false) => { try { const selection = window.getSelection(); const input = document.createElement('input'); if (selection) { selection.removeAllRanges(); } if (isIos) { input.setAttribute('type', 'text'); input.style.position = 'fixed'; input.style.opacity = '0'; document.body.appendChild(input); input.focus(); input.blur(); } document.body.removeChild(input); } catch (error) { console.log(error) } },
    RenderRightSidePanel: () => { console.log("RenderRightSidePanel") },
    MessageConvertionRender: (id) => { console.log("MessageConvertionRender") },
    RightSidePanelSetHeight: () => { console.log("RightSidePanelSetHeight") },
    SelectCustommerBYHasID: (id = "") => { console.log("SelectCustommerBYHasID: " + id) },
    MobileCallBackForBack: () => { console.log("initial Mobile CallBack For Back") },
    DestroyDropdown: (type, data) => { console.log("initial DestroyDropdown"); },
    PinConvertion: (Id, ConID, isPin) => { console.log("initial PinConvertion"); },
    NewChatClick: () => { console.log("initial NewChatClick"); },
    MarkMessageUnread: (Id, UserID, messageId, isRead, IsFromClick, custHashId, isFromSignalR, message) => { console.log("initial MarkMessageUnread"); },
    RemoveChat: (type, ConID) => { console.log("initial RemoveChat"); },
    BlockConvertion: (type, data) => { console.log("initial BlockConvertion"); },
    MuteNotification: (Id, conId, Mute) => { console.log("initial MuteNotification"); },
    OpenSelect2: () => { console.log("initial Open Select 2"); },
    CloseSelect2: () => { console.log("initial Close Select 2"); },
    AccessPermissionGranted: () => { console.log("initial Photo Access Permission "); },
    CheckViewRights: () => { console.log("initial Check View Rights"); return false; },
    CheckModifyRights: () => { console.log("initial Check Modify Rights"); return false; },
    HandleInputFocus: () => {
        try {
            setTimeout(() => {
                let inputs = document.querySelectorAll('input');
                inputs.forEach((input) => {
                    input.addEventListener('focus', () => { window.InputElemntsFocued = true; });
                    input.addEventListener('blur', () => { window.InputElemntsFocued = false; });
                });
                return () => {
                    inputs.forEach((input) => {
                        input.removeEventListener('focus', () => { window.InputElemntsFocued = true; });
                        input.removeEventListener('blur', () => { window.InputElemntsFocued = false; });
                    });
                };
            }, 500);
        } catch (error) {
            console.log(error)
        }
    },


};

export const SelectionControlTypeEnum = {
    None: "None",
    New: "New",
    Customer: LeftControlNames.Customer,
    Employee: LeftControlNames.Employee

}
export const ChatListSelection = {
    All: "All",
    Unread: "Unread",
}
export const ConnectionStatus = {
    Connected: "Connected",
    Reconnecting: "Reconnecting",
    Failed: "Failed",
    Closed: "Closed",
}

export const MassageTypeEnum = {
    Block: "Block",
    ChageGroupName: "ChageGroupName",
    AddMamber: "AddMamber",
    RemoveMamber: "RemoveMamber",
};

export const PopupTypes = {
    BlockUser: 1,
    RemoveChat: 2,
    UnBlockUser: 3,
    LeaveChat: 4
}
export const MobileCallBackAction = {
    EmployeeProfile: 119,
    CustomerProfile: 114,
    AccessPermission: 116,
    DownlodImage: 117,
    Calender: 115,
    PhoneNumber: 1,
    HidekeyBord: 9,
    RedirectToLink: 6,
    ImageGallary: 53,
    OpenTextMessaging: 118,
    BagdeCountForConnectApp: 126,
    PalyVideoPlayer: 131,
    DownlodVideo: 132,
    DoVibrate:133,
    DoHeptic : 134,
    AiSettingHistory : 137,
    CopyText : 64

}
export const NewDummyData = [
    {
        "conversationId": "",
        "customerIdHash": "",
        "customerId": "",
        "name": "New Chat",
        "lastMessage": "",
        "lastMessageDate": "",
        "isPin": false,
        "isMute": false,
        "userType": 0,
        "isGroupChat": false,
        "messageId": "",
        "isRead": true,
        "profileImage": "",
        "cdnUrl": "",
        "firstName": "",
        "lastName": "",
        "isBlocked": false,
        "pageIndex": 0,
        "tempProfileImage": "<i class='fa-regular fa-user'></i>",
        "isBusinessExcluded": false
    },
];


export const VagaroConnectMessageType =
{
    ChannelSubscriptionMessage: 1,
    EmployeeChatMessage: 2,
    CustomerMessageSentByCustomer: 3,
    CustomerMessageSentByEmployee: 4,
    ReadStatusMessage: 5,
    CustomerHasRemovedChannel: 6,
    CustomerRemoveChat: 7,
    EditMessage: 8,
    DeleteMessage: 9,
    UndoDeleteMessage: 10,
    BlockCustomer: 11,
    UnblockCustomer: 12,
    ChangeGroupName: 13,
    AddMemberInGroup: 14,
    RemoveMemberFromGroup: 15,
    CustomerIsOffline: 16,
    CustomerIsOnline: 17,
    UpdateTextUsage: 18,
    AddMemberInGroupWithHistory: 19,
    UserTyping: 20,
    TakeControl : 21,
    CustomerMessageSentByAI: 22,
    AItyping: 23,
    AIActive: 24,
}
export const FileStatus = {
    Idle: "Idle",
    Loding: "Loding",
    Done: "Done",
    Failed: "Failed"
};

export const AIContentEnum = {
    ContentLoad: "ContentLoad",
    Loader: "Loader",
    ConnectSettingWrap: "ConnectSettingWrap",
    RightWrapper:'RightWrapper',
    BackButton: "BackButton"
};
export const middleScrollHeight = 500;
export const AiSettingModuleId = 2010;

// Slider settings
export const SliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 3500,
    slidesToShow: 1,
    arrows: false,
    //adaptiveHeight: true, 
    slidesToScroll: 1,
};


