import React, { createContext, useContext, useRef, useState } from "react";
const VagaroAIContext = createContext();

export const VagaroAIProvider = ({ children, initialData }) => {
  const [showLoader, setShowLoader] = useState(false);
  const AIContent = useRef({
    ContentLoad: false,
  });

  const SetAIContent = (key, value) => {
    AIContent.current[key] = value;
  };
  
  const contextValue = {
    ...initialData,
    showLoader,
    setShowLoader,
    AIContent,
    SetAIContent
  };

  return (
    <VagaroAIContext.Provider value={contextValue}>
      {children}
    </VagaroAIContext.Provider>
  );
};

export const useVagaroAIContext = () => {
  return useContext(VagaroAIContext);
};