import React from 'react'
import { useSelector } from 'react-redux';
import { AiSettingWrpper, DeActivate, HandleAISettings, HandlePause, HeaderBanner, SalesPage, WorkingDays, AiLastModified } from './Components'
import { AIContentEnum, StatusEnum } from '../../Binding/ChatBoatEnums';
import { useVagaroAIContext } from '../../Store/VagaroAIContext';

const RightComponent = () => {
    const Settings_Status = useSelector((state) => state.Settings.Status);
    const { SetAIContent } = useVagaroAIContext();
    return (
        <div ref={(ele) => SetAIContent(AIContentEnum.RightWrapper, ele)} className={Settings_Status === StatusEnum.Idle ? 'connect-right-wrapper staincile' : 'connect-right-wrapper'}>
            <div className='salsePageMainWrap'>
                <HeaderBanner />
                <AiSettingWrpper>
                    <HandleAISettings />
                    <HandlePause />
                </AiSettingWrpper>
                <SalesPage />
                <WorkingDays />
                <DeActivate />
                <AiLastModified />
            </div>
        </div>
    );
};

export default RightComponent