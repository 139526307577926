import { createSlice } from '@reduxjs/toolkit';

const TypingUserSlice = createSlice({
  name: 'Typing',
  initialState: {
    Users:[]
  },
  reducers: {
    UpdateTypingUser: (state, action) => {
      const payload = action.payload
      const TempUsers = state.Users.filter(prev => prev.userId !== payload.userId);
      payload.LastUpdateTime = Date.now()
      TempUsers.push(payload)
      state.Users = TempUsers
    },
    CheckTypingUser: (state, action) => {
      const TempUsers = state.Users.filter(prev => prev.userId !== action.payload.userId);
      state.Users = TempUsers
    },
    RemoveTypingUser: (state, action) => {
      const exixist = state.Users.filter(prev => prev.userId === action.payload.userId && prev.id === action.payload.id);
      if (exixist.length > 0) {
        state.Users = state.Users.filter(prev => prev.userId !== action.payload.userId);
      }
    },
    ResetTypingUser: (state) => {
      state.Users = [];
    },
  },
});

export const { UpdateTypingUser, CheckTypingUser, RemoveTypingUser, ResetTypingUser } = TypingUserSlice.actions;
export const TypingUserReducer = TypingUserSlice.reducer;
