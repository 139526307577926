import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useSelector } from 'react-redux';
import $ from "jquery";
import { utils } from "../../../utils/Utils";
import "../../../utils/timepicker.js";

import "./timepicker.scss";

const TimePicker = (props) => {
    const [isandroidiospro, setisandroidiospro] = useState(null);
    const [tempValue, setTempValue] = useState(props.defaultValue);
    const isPaused = useSelector((state) => state.Settings.Pause);
    const UserAccess = useSelector((state) => state.Settings.UserAccess);

    useEffect(() => {
        const handleScroll = () => {
            const timePicker = document.querySelector('.ui-timepicker-standard');
            if (timePicker && window.getComputedStyle(timePicker).display !== 'none') {
                timePicker.style.display = 'none';
            }
        };

        const elements = document.querySelectorAll('.connect-right-wrapper');

        elements.forEach((element) => {
            element.addEventListener('scroll', handleScroll);
        });

        return () => {
            elements.forEach((element) => {
                element.removeEventListener('scroll', handleScroll);
            });
        };
    }, []);

    const disableScroll = () => {
        // if (UserAccess === false) {
        //     utils.ShowAlert("Restricted Access", "You have not been given access to this page. Contact your account admin or business owner to get access.", "Close")
        // } else {
        //     const element = document.querySelector('.connect-right-wrapper');
        //     if (element) {
        //         setTimeout(() => {
        //             const timePicker = document.querySelector('.ui-timepicker-standard');
        //             if (timePicker && timePicker && window.getComputedStyle(timePicker).display !== 'none') {
        //                 element.style.overflow = 'hidden';
        //             }
        //         }, 50);
        //     }
        // }
    };

    const enableScroll = () => {
        // const element = document.querySelector('.connect-right-wrapper');
        // if (element) {
        //     setTimeout(() => {
        //         element.style.overflow = 'auto';
        //     }, 20);
        // }
    };

    const ApplyTimePicker = () => {
        if (!isPaused) {
            const iconElement = $('#' + props.ControlID + "icon");
            // Ensure the timepicker plugin is loaded
            if (typeof $.fn.timepicker === 'undefined') {
                console.error('jQuery Timepicker plugin is not loaded.');
                return;
            }

            // Destroy existing timepicker instance if any
            if (iconElement.data('timepicker')) {
                iconElement.timepicker('destroy');
            }

            if (!props.isDisableTime) {
                iconElement.timepicker({
                    inputid: props.ControlID,
                    showOn: "button",
                    timeFormat: 'h:mm p',
                    interval: 30,
                    dynamic: false,
                    dropdown: true,
                    scrollbar: true,
                    defaultTime: props.defaultTime,
                    change: function (time) {
                        const formattedTime = $(this).timepicker('format', time);
                        setTempValue(formattedTime); // Update tempValue with the selected time
                        props.onChange(formattedTime);
                        enableScroll();
                    },
                });

                // Add event listeners for show and hide events
                iconElement.on('click', function () {
                    disableScroll();
                    // Scroll to the selected time when the time picker dropdown is opened
                    setTimeout(() => {
                        const inputElement = $('#' + props.ControlID);
                        const selectedTime = inputElement.val();
                        const dropdown = $('.ui-timepicker-container .ui-timepicker-viewport');

                        // Scroll to the selected time
                        if (dropdown.length) {
                            const selectedOption = dropdown.find(`a:contains(${selectedTime})`).filter(function () {
                                return $(this).text().trim() === selectedTime;
                            }).first();

                            if (selectedOption.length) {
                                dropdown.find('li').removeClass('ui-timepicker-selected');
                                const selectedLi = selectedOption.closest('li');
                                selectedLi.addClass('ui-timepicker-selected');
                                const optionTop = selectedOption.offset().top;
                                const dropdownTop = dropdown.offset().top;
                                const scrollTop = dropdown.scrollTop();
                                const newScrollTop = scrollTop + (optionTop - dropdownTop);
                                dropdown.scrollTop(newScrollTop);
                            }
                        }
                    }, 100);
                }).on('blur', function () {
                    setTimeout(enableScroll, 100);
                });
            }
        }
    };


    useEffect(() => {
        utils.CheckIsFromProApp(setisandroidiospro);
    }, []);

    useEffect(() => {
        if (isandroidiospro === false) {
            const inputElement = $('#' + props.ControlID);

            if (props.isDisableTime) {
                inputElement.prop('disabled', true);
                $('#' + props.ControlID + "icon").off('click');
            } else {
                inputElement.prop('disabled', false);
                ApplyTimePicker();
            }
        }
    }, [props.isDisableTime]);

    useEffect(() => {
        if (UserAccess && isandroidiospro === false) {
            ApplyTimePicker();
        }
    }, [isandroidiospro]);

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    };

    return (
        <div>
            <Form.Control
                required
                id={props.ControlID}
                className={props.className}
                placeholder={props.placeholder}
                value={tempValue}
                onClick={(e) => {
                    if (UserAccess === false) {
                        utils.ShowAlert("Restricted Access", "You have not been given access to this page. Contact your account admin or business owner to get access.", "Close")
                    }else{
                        if (isPaused) {
                            e.preventDefault();
                        } else if (typeof props.onClick === 'function') {
                            props.onClick(e);
                        }
                    }            
                }}
                onChange={(e) => {
                    setTempValue(e.target.value);
                }}
                onBlur={(e) => {
                    const roundedValue = roundToNearestFive(e.target.value);
                    setTempValue(roundedValue);
                    props.onChange(roundedValue);
                }}
                disabled={isPaused ? true : props.isDisableTime}
                onKeyDown={handleKeyDown}
            />
            <div
                className={`bs-field-icon wh  datetimeicon ${props.isDisableTime ? 'disabled' : ''}`}
                id={props.ControlID + "icon"}
                title="Open the time view popup"
                // onClick={disableScroll}
                onClick={(e) => {
                    if(!props.isDisableTime && UserAccess === false){
                        utils.ShowAlert("Restricted Access", "You have not been given access to this page. Contact your account admin or business owner to get access.", "Close")
                    }
                            
                }}
                onBlur={enableScroll}
            ></div>
        </div>
    );
};

export const roundToNearestFive = (time) => {
    let hours, minutes, period;

    if (/^\d+$/.test(time)) {
        hours = parseInt(time);
        minutes = 0;
        period = hours >= 12 ? 'PM' : 'AM';
        if (hours > 12) {
            hours -= 12;
        }
    } else {
        const timePattern = /^(0?[1-9]|1[0-2]):?[0-5]?[0-9]?\s?(AM|PM)?$/i;
        if (!timePattern.test(time)) {
            return time;
        }

        const timeParts = time.split(/[ :]/);
        hours = parseInt(timeParts[0]);
        minutes = timeParts[1] ? parseInt(timeParts[1]) : 0;
        period = timeParts[2] ? timeParts[2].toUpperCase() : (hours >= 12 ? 'PM' : 'AM');

        if (!timeParts[1]) {
            minutes = 0;
        }
        if (hours > 12) {
            hours -= 12;
            period = 'PM';
        }
    }

    minutes = Math.ceil(minutes / 5) * 5;
    if (minutes === 60) {
        minutes = 0;
        hours += 1;
        if (hours === 12) {
            period = period === 'AM' ? 'PM' : 'AM';
        } else if (hours > 12) {
            hours = 1;
        }
    }

    const roundedTime = `${hours}:${minutes.toString().padStart(2, '0')} ${period}`;
    return roundedTime;
};

export default TimePicker;
